import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import TherapistRegistrationStep1 from './TherapistRegistrationStep1';
import TherapistRegistrationStep2 from './TherapistRegistrationStep2';
import TherapistRegistrationStep3 from './TherapistRegistrationStep3';
import TherapistRegistrationStep4 from './TherapistRegistrationStep4';
import './registrationForm.css';

const TherapistRegistration = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    birthDate: '',
    phone: '',
    qualification: '',
    experience: '',
    areasOfExpertise: [],
    consultationType: 'online',
    description: '',
    specialties: '',
    video: null,
    profilePhoto: null,
    contact: '',
    email: '',
    socialMedia: '',
    website: '',
    consultationFee: ''
  });
  const [step, setStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      const updatedAreasOfExpertise = checked
        ? [...prevData.areasOfExpertise, value]
        : prevData.areasOfExpertise.filter((area) => area !== value);
      return { ...prevData, areasOfExpertise: updatedAreasOfExpertise };
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files[0]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSend.append(key, formData[key]);
      });
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/registration-therapist/${user.uid}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/users/${user.uid}`, { registration: true });
      setUser({ ...user, registration: true });
      navigate('/therapist-home');
    } catch (error) {
      console.error('Error saving registration data:', error);
    }
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <div className="registration-form">
      <h1>Complete your registration</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        {step === 1 && <TherapistRegistrationStep1 formData={formData} handleChange={handleChange} handleFileChange={handleFileChange} />}
        {step === 2 && <TherapistRegistrationStep2 formData={formData} handleCheckboxChange={handleCheckboxChange} />}
        {step === 3 && <TherapistRegistrationStep3 formData={formData} handleChange={handleChange} />}
        {step === 4 && <TherapistRegistrationStep4 formData={formData} handleChange={handleChange} handleFileChange={handleFileChange} />}
        
        <div className="form-navigation">
          {step > 1 && <button type="button" onClick={prevStep}>Previous</button>}
          {step < 4 && <button type="button" onClick={nextStep}>Next</button>}
          {step === 4 && <button type="submit">Submit</button>}
        </div>
      </form>
    </div>
  );
};

export default TherapistRegistration;
