// frontend/src/AppRoutes.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import LoginPage from '../pages/Login';
import PatientHome from '../pages/PatientHome';
import TherapistHome from '../pages/TherapistHome';
import ResetPassword from '../components/Login/ResetPassword';
import SignUp from '../components/Login/SignUp';
import UserTypeSelection from '../pages/UserTypeSelection';
import ProtectedRoute from '../components/ProtectedRoute';
import Logout from '../components/Login/Logout';
import { useAuth } from '../hooks/useAuth';
import PatientRegistrationStep1 from '../components/registration/PatientRegistrationStep1';
import PatientRegistrationStep2 from '../components/registration/PatientRegistrationStep2';
import TherapistRegistration from '../components/registration/TherapistRegistration';

const Loading = () => (
  <div className="loading">Loading...</div>
);

const AppRoutes = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<UserTypeSelection />} />
        <Route path="/sign-up/:type" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/patient-home"
          element={
            <ProtectedRoute>
              {user?.registration === false ? (
                <PatientRegistrationStep1 />
              ) : (
                <PatientHome />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/therapist-home"
          element={
            <ProtectedRoute>
              {user?.registration === false ? (
                <TherapistRegistration />
              ) : (
                <TherapistHome />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-patient-step1"
          element={
            <ProtectedRoute>
              <PatientRegistrationStep1 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-patient-step2/:accountType"
          element={
            <ProtectedRoute>
              <PatientRegistrationStep2 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-therapist"
          element={
            <ProtectedRoute>
              <TherapistRegistration />
            </ProtectedRoute>
          }
        />
        <Route
          path="/logout"
          element={
            <ProtectedRoute>
              <Logout />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
