import React from 'react';

const TherapistRegistrationStep2 = ({ formData, handleCheckboxChange }) => {
  const areasOfExpertiseOptions = [
    'Terapias individuais para casais',
    'Terapias em grupo para casais',
    'Terapias alternativas',
    'Terapias para divórcios',
    'Terapias para viúvos',
    'Terapias para solteiros',
    'Terapias com sexólogas'
  ];

  return (
    <div className="registration-step">
      <h2>Areas of Expertise</h2>
      {areasOfExpertiseOptions.map((area) => (
        <div key={area} className="checkbox-container">
          <input
            type="checkbox"
            value={area}
            checked={formData.areasOfExpertise.includes(area)}
            onChange={handleCheckboxChange}
            className="checkbox-input"
          />
          <label className="checkbox-label">{area}</label>
        </div>
      ))}
    </div>
  );
};

export default TherapistRegistrationStep2;
