import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../hooks/useAuth';
import ShowProfileTherapist from '../components/perfil/ShowProfileTherapist';
import TherapistRegistration from '../components/registration/TherapistRegistration';

const TherapistHome = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [registrationData, setRegistrationData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/users/${user.uid}`);
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error.response ? error.response.data : error.message);
      }
    };

    const fetchRegistrationData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/registration-therapist/${user.uid}`);
        setRegistrationData(response.data);
      } catch (error) {
        console.error('Error fetching registration data:', error.response ? error.response.data : error.message);
      }
    };

    if (user) {
      fetchUserData();
      fetchRegistrationData();
    } else {
      navigate('/login');
    }
  }, [user, navigate]);

  if (!userData || !registrationData) {
    return <div>Loading...</div>;
  }

  if (!userData.registration) {
    return <TherapistRegistration />;
  }

  return (
    <div className="homePage">
      <ShowProfileTherapist therapistData={registrationData} />
    </div>
  );
};

export default TherapistHome;
