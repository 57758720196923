import React from 'react';
import './styles.css';
import logo from '../img/logo.png';
// import logo from '../../img/logo.png';

const Lp = () => {
  return (
    <div className="lph">
        <div className="logo-container">
          <a href={process.env.REACT_APP_HOME_URL}>
            <img src={logo} alt="TherapyConnect Logo" className="logo" />
          </a>
        </div>
      <div className="tent">
        <h1>Welcome to TherapyConnect!</h1>   
        <h2>Your journey to well-being starts here.</h2>   
      </div>
      <div className="login">
        <button onClick={() => window.location.href='/login'}>Start</button>
      </div>
    </div>
  );
};

export default Lp;
