import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import Swal from 'sweetalert2';
import logo from '../../img/logo.png';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      Swal.fire('Error', 'Invalid email format.', 'error');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      Swal.fire('Success', 'Password reset email sent successfully.', 'success');
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    }
  };

  return (
    <div>
        <div className="lph">
        <div className="logo-container">
          <a href={process.env.REACT_APP_HOME_URL}>
            <img src={logo} alt="TherapyConnect Logo" className="logo" />
          </a>
        </div>
        <div className='lph-title'>
        <h1>Reset Password</h1>
        </div>
        <form className="login" onSubmit={handleResetPassword}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="login-input"
        
        />
        <button type="submit">Reset Password</button>
      </form>
      <div className='link-su'>
        <Link to="/login" className="login-link">Go to Login</Link>
      </div>
      </div>
     

      
    </div>
  );
};

export default ResetPassword;
