import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PatientRegistrationStep1 = () => {
  const navigate = useNavigate();

  const handleSelection = (accountType) => {
    navigate(`/register-patient-step2/${accountType}`);
  };

  return (
    <div className="user-type-selection">
      <h1>Are you registering as an individual or a couple?</h1>
      <div className="buttons">
        <button className="user-type-button" onClick={() => handleSelection('individual')}>
          Individual
        </button>
        <button className="user-type-button" onClick={() => handleSelection('couple')}>
          Couple
        </button>
      </div>
    </div>
  );
};

export default PatientRegistrationStep1;
