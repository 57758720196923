import React from 'react';

const ShowProfileTherapist = ({ therapistData }) => {
  if (!therapistData) {
    return <div>Loading profile...</div>;
  }

  const profilePhotoUrl = `${process.env.REACT_APP_API_BASE_URL}/${therapistData.profilePhoto}`;
  const videoUrl = `${process.env.REACT_APP_API_BASE_URL}/${therapistData.video}`;

  return (
    <div className="profile-container">
      <h2>{therapistData.name}</h2>
      <div className="profile-detail">
        {therapistData.profilePhoto && (
          <div>
            <img
              src={profilePhotoUrl}
              alt="Profile"
              className="profile-photo"
            />
          </div>
        )}
        <p><strong>Address:</strong> {therapistData.address}</p>
        <p><strong>Birth Date:</strong> {therapistData.birthDate}</p>
        <p><strong>Phone:</strong> {therapistData.phone}</p>
        <p><strong>Qualification:</strong> {therapistData.qualification}</p>
        <p><strong>Experience:</strong> {therapistData.experience}</p>
        <p><strong>Areas of Expertise:</strong> {therapistData.areasOfExpertise.join(', ')}</p>
        <p><strong>Consultation Type:</strong> {therapistData.consultationType}</p>
        <p><strong>Description:</strong> {therapistData.description}</p>
        <p><strong>Specialties:</strong> {therapistData.specialties}</p>
        {therapistData.video && (
          <div>
            <video controls className="profile-video">
              <source
                src={videoUrl}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        <p><strong>Contact:</strong> {therapistData.contact}</p>
        <p><strong>Email:</strong> {therapistData.email}</p>
        <p><strong>Social Media:</strong> {therapistData.socialMedia}</p>
        {therapistData.website && (
          <p><strong>Website:</strong> <a href={therapistData.website} target="_blank" rel="noopener noreferrer">{therapistData.website}</a></p>
        )}
        <p><strong>Consultation Fee:</strong> ${therapistData.consultationFee}</p>
      </div>
    </div>
  );
};

export default ShowProfileTherapist;
