import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../hooks/useAuth';
import './registrationForm.css';

const PatientRegistrationStep2 = () => {
  const { accountType } = useParams();
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    birthDate: '',
    phone: '',
    partnerName: '',
    partnerBirthDate: '',
    partnerPhone: '',
    profilePhoto: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      profilePhoto: e.target.files[0]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === 'profilePhoto') {
          formDataToSend.append(key, formData[key]);
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });
      if (accountType === 'individual') {
        formDataToSend.delete('partnerName');
        formDataToSend.delete('partnerBirthDate');
        formDataToSend.delete('partnerPhone');
      }
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/registration/${user.uid}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/users/${user.uid}`, { registration: true });
      setUser({ ...user, registration: true });
      navigate('/patient-home');
    } catch (error) {
      console.error('Error saving registration data:', error);
    }
  };

  return (
    <div className="registration-form">
      <h1>Complete your registration</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name"
          required
        />
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Address"
          required
        />
        <input
          type="date"
          name="birthDate"
          value={formData.birthDate}
          onChange={handleChange}
          placeholder="Birth Date"
          required
        />
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone"
          required
        />
        {accountType === 'couple' && (
          <>
            <input
              type="text"
              name="partnerName"
              value={formData.partnerName}
              onChange={handleChange}
              placeholder="Partner's Name"
              required
            />
            <input
              type="date"
              name="partnerBirthDate"
              value={formData.partnerBirthDate}
              onChange={handleChange}
              placeholder="Partner's Birth Date"
              required
            />
            <input
              type="tel"
              name="partnerPhone"
              value={formData.partnerPhone}
              onChange={handleChange}
              placeholder="Partner's Phone"
              required
            />
          </>
        )}
        <label htmlFor="profilePhoto">Profile Photo</label>
        <input
          type="file"
          name="profilePhoto"
          accept="image/*"
          onChange={handleFileChange}
          required
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PatientRegistrationStep2;
